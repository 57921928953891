import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useImageForData } from "../../hooks/useAllImages";

const LogoItem = ({ title, imgPath }) => (
  <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
    <GatsbyImage
      image={useImageForData(imgPath)}
      className="h-12"
      imgClassName="h-12"
      alt={title}
    />
  </div>
);

export default LogoItem;
