import React from "react";

const GradientFeatureSection = ({title, description, children}) => (
  <div className="bg-gradient-to-r from-purple-800 to-indigo-700">
    <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
      <h2 className="text-3xl font-extrabold text-white tracking-tight">
        {title}
      </h2>
      <p className="mt-4 max-w-3xl text-lg text-purple-200">
        {description}
      </p>
      <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
        {children}
      </div>
    </div>
  </div>
);

export default GradientFeatureSection;
