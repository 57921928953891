import React from "react";

const LogoSection = ({ title, children }) => (
  <div className="bg-gray-100">
    <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
      <p className="text-center text-sm font-semibold uppercase text-gray-500 tracking-wide">
        {title}
      </p>
      <div className="mt-6 grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
        {children}
      </div>
    </div>
  </div>
);

export default LogoSection;
