import React from "react";
import Header from "../components/sections/Header";
import HeroSection from "../components/sections/HeroSection";
import LogoSection from "../components/sections/LogoSection";
import LogoItem from "../components/items/LogoItem";
import FeatureSection from "../components/sections/FeatureSection";
import GradientFeatureSection from "../components/sections/GradientFeatureSection";
import StatsSection from "../components/sections/StatsSection";
import CTASection from "../components/sections/CTASection";
import Footer from "../components/sections/Footer";
import FeatureItem from "../components/items/FeatureItem";
import GradientFeatureItem from "../components/items/GradientFeatureItem";
import { AnnotationIcon } from "@heroicons/react/outline";
import StatsItem from "../components/items/StatsItem";

const indexPage = () => (
  <div className="bg-white">
    <Header />
    <HeroSection
      title="Fast development of small frontend projects on React"
      description="Creation of responsive, fast-loading websites and applications"
      imgPath=""
      firstBtnText="Contact us"
      firstBtnPath=""
      secondBtnText="Open portfolio"
      secondBtnPath=""
    />

    <LogoSection title="OUR PROJECTS">
      <LogoItem title="codeexpert" imgPath="logos/codeexpert.webp" />
      <LogoItem title="itgrows" imgPath="logos/itgrows.webp" />
      <LogoItem title="redmine" imgPath="logos/redmine.png" />
      <LogoItem title="posexpert" imgPath="logos/posexp.webp" />
      <LogoItem title="srv4pos" imgPath="logos/logosrv4pos.webp" />                        
      <LogoItem title="posplus" imgPath="logos/posplus.webp" />
      <LogoItem title="skattedosan" imgPath="logos/skat.webp" />
      <LogoItem title="gitt" imgPath="logos/gitt.png" />
    </LogoSection>

    <FeatureSection>
      <FeatureItem title="React Mega Team" description="Based on Jamstack technologies (JavaScript, APIs, Markup), facilitating the creation of responsive, fast-loading websites and applications" btnText="Contact us" btnPath="" imgPath="/team.jpg" />
    </FeatureSection>

    <GradientFeatureSection title="Features" description="">
      <GradientFeatureItem title="GraphicDesigner-less" description="React Mega Team utilizes Tailwind for design concepts" Icon={AnnotationIcon} />
      <GradientFeatureItem title="Backend-less" description="RMT provides frontend only" Icon={AnnotationIcon} />
      <GradientFeatureItem title="DevOps-less" description="Focus on application monitoring rather than server provisioning" Icon={AnnotationIcon} />
      <GradientFeatureItem title="Developer-less" description=" Developers engaged for infrastructure setup only" Icon={AnnotationIcon} />
      <GradientFeatureItem title="Easy integration" description="React components are easily importable into various CMS or other IPAs" Icon={AnnotationIcon} />
      <GradientFeatureItem title="No programming" description="Static site generation without programming logic" Icon={AnnotationIcon} />
      <GradientFeatureItem title="For developers" description="Allows for a break from major projects and experimentation with new technologies" Icon={AnnotationIcon} />
      <GradientFeatureItem title="For companies" description="Facilitates rapid processing and development of small projects" Icon={AnnotationIcon} />
    </GradientFeatureSection>
    
    <StatsSection subTitle="MORE" title="Staging" description="The workflow pipeline includes the opportunity to preview prototypes that share exact replicas of how the web-site is rendered online and can be discussed before release. Content managers get real feedback about their work of their drafts in staging environments." imgPath="/team2.jpg">
      <StatsItem stat="<98%" emphasis="transactions" rest="are proceeded less than 1 sec" />
      <StatsItem stat=">20 000" emphasis="requests per hour" rest="are proceeeding via our server" />
      <StatsItem stat=">500" emphasis="merchants" rest="are integrated and keep their data on srv4pos" />
      <StatsItem stat=">4000 " emphasis="POS" rest="are connected and send data" />
    </StatsSection>

    <CTASection
      title="Ready to get started?"
      highlightedTitle="Get in touch or create your website."
      firstBtnText="Contact us"
      fistBtnPath=""
      secondBtnText="Open Portfolio"
      secondBtnPath=""
    />
    <Footer />
  </div>
);

export default indexPage;
