import React from "react";

const GradientFeatureItem = ({ title, description, Icon }) => (
  <div key={title}>
    <div>
      <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
        <Icon className="h-6 w-6 text-white" aria-hidden="true" />
      </span>
    </div>
    <div className="mt-6">
      <h3 className="text-lg font-medium text-white">{title}</h3>
      <p className="mt-2 text-base text-purple-200">{description}</p>
    </div>
  </div>
);

export default GradientFeatureItem;
